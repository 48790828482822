export const API_URL = {
  UPLOAD: "api/v1/users/media/upload",
  DELETE_FILE: "api/v1/users/media/delete",
  GET_FILE: "api/v1/file-manager/media/files",
  // GET_SINGLE_FILE: "/api/v1/file-manager/media/file",
  FILE_SAVE: "/api/v1/users/media/save",
  FOLDER: "/api/v1/users/media/folders",
  FILE_RENAME: "/api/v1/users/media/rename",
  // GET_ALL_FILES: "/api/v1/users/media/file-managers",
  GET_ALL_FILES: "/api/v1/users/media/get/:rocketShipId",
  GET_FAVORITE_FILES: "/api/v1/users/media/favorite",
  // GET_SINGLE_FILE: "/api/v1/users/media/file-managers/:id",
  GET_SINGLE_FILE: "/api/v1/users/media/get/:rocketShipId",
  GET_ROCKETSHIPS: "/api/v1/users/media/rocketships/list",
  DOWNLOAD_FILE: "/api/v1/users/media/download",
  AUTHENTICATE: "/api/v1/users/media/authenticate",
};
