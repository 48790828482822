import React from "react";
import { useDispatch } from "react-redux";
import logo from "../../Asssets/Images/logo.png";
import { setUser } from "../../reducer/user.slice";
import Button from "../common/Button/Button";
import "./Header.scss";
import { setRocketShipId } from "../../reducer/rocketShipId.slice";


const Header = () => {
    const dispatch = useDispatch();
    return (
        <>
            <div className="container-fluid">
                <div className="header">
                    <div className="logo">
                        <a href="/"><img src={logo} alt="" /></a>
                    </div>
                    <ul className="p-0 menu">
                        <li>
                            <a href="/">Rocketship Dashboards</a>
                        </li>
                    </ul>
                    <ul className="contact ms-auto">
                        <li>
                            <Button fluid onClick={() => {
                                dispatch(setUser(null));
                                dispatch(setRocketShipId(null))
                            }}>Logout</Button>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
export default Header;