import { Spinner } from "react-bootstrap";
import "./Button.scss";
import React from 'react'

const Button = ({ text, loading, disabled, children, fluid, className, ...rest }) => {
    return (
        <>
            <button
                {...rest}
                disabled={loading || disabled}
                className={`custom_btn ${fluid ? "w-100" : ""} ${className || ""}`}
            >
                {
                    loading ?
                        <Spinner /> :
                        text || children
                }
            </button>
        </>
    )
}

export default Button
