import React from 'react'
import "./Loader.scss";
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const Loader = () => {
    const { loading } = useSelector(state => state.loading);
    if (loading) {
        return (
            <div className="global_loader">
                <Spinner />
            </div>
        )
    }
    else {
        return null;
    }
}

export default Loader
