import React from 'react';
import folderIcon from "../../Asssets/Images/folder-alt.svg";
import CardActionDots from './CardActionDots';
import { getFileTypeIcon } from '../../utils/getFileTypeIcon';

const ListCard = ({ handleImageClick, getAllFiles, icon, type, name, id }) => {
    return (
        <>
            <div className="files_list">
                <div className={`heading_top ${type === "folder" ? "folder_box" : ""}`}>
                    <div className="files_list_inner heading_top_first">
                        <input type="checkbox" />
                        <div onClick={type === "folder" ? handleImageClick : () => { }} className="file_list_img">
                            <img src={type === "file" ? getFileTypeIcon(name) : folderIcon} alt="" />
                        </div>
                        <p onClick={type === "folder" ? handleImageClick : () => { }} className="mb-0">{name}</p>
                    </div>
                    {/* <div className="time heading_top_second">Today,08:29 AM</div>
                    <div className="time heading_top_third">Only Me</div> */}
                    <div className="ms-auto"><CardActionDots showDownloadButton={type !== "folder"} getAllFiles={getAllFiles} name={name} id={id} /></div>
                </div>
            </div >
        </>
    )
}

export default ListCard
