import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { ImFolderPlus, ImFolderUpload, ImSearch, ImStarFull } from "react-icons/im";
import { IoHomeOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "react-select";
import { useDebounce } from 'use-debounce';
import { GridAndListViewIcon, GridViewIcon, ListViewIcon, RightArrow } from "../../Asssets/icons/icons";
import useFileFolder from "../../hooks/useFileFolder";
import useUserFavoriteFolder from "../../hooks/userFavoriteFolder";
import { setRocketShipId } from "../../reducer/rocketShipId.slice";
import { API_URL } from "../../utils/ApiUrl";
import HeaderBar from "../HeaderBar/HeaderBar";
import { handleCreateFolder } from "../HeaderBar/handleCreateFolder";
import UploadBtnModal from "../UploadBtnModal/UploadBtnModal";
import Button from "../common/Button/Button";
import UploadingFilesDropzone from "../modal/UploadingFilesDropzone/UploadingFilesDropzone";
import "./Dashlite.scss";
import GridCard from "./GridCard";
import ViewType from "./ViewType";
// export const rocketShipId = "6e49003e-e830-4320-a4ec-4b6cf12af13c";

export const channelOpions = [
	{
		label: "DEFAULT",
		value: "DEFAULT",
	},
	{
		label: "SEO",
		value: "SEO",
	},
	{
		label: "Local SEO",
		value: "Local SEO",
	},
];
export const labelOptions = [
	{
		value: "DEFAULT",
		label: "DEFAULT",
	},
	{
		value: "Screaming Frog Crawl Data",
		label: "Screaming Frog Crawl Data",
	},
	{
		value: "Majestic Referring Domains Report",
		label: "Majestic Referring Domains Report",
	},
	{
		value: "Majestic Pages Report",
		label: "Majestic Pages Report",
	},
	{
		value: "Moz Linking Domains Report",
		label: "Moz Linking Domains Report",
	},
	{
		value: "Moz Ranking Keywords Report",
		label: "Moz Ranking Keywords Report"
	},
];

export const orderByOptions = [
	{
		label: "Ascending",
		value: "ASC",
	},
	{
		label: "Descending",
		value: "DESC",
	},
]


const Dashlite = () => {
	// const [rocketShipId, setRocketShipId] = useState(null);
	const { rocketShipId } = useSelector(state => state.rocketShipId);
	const dispatch = useDispatch();
	const [show, setShow] = useState(false);
	const [showFavourites, setShowFavourites] = useState(false);
	const [showUploading, setShowUploading] = useState(false);
	const [fileManager, setFileManager] = useState(`${API_URL.GET_ALL_FILES.replace(":rocketShipId", rocketShipId)}`);
	const [parentId, setParentId] = useState(null);
	const [currentFolderKey, setCurrentFolderKey] = useState(null);
	const [directories, setDirectories] = useState([]);
	const [viewType, setViewType] = useState("list");
	const { allFiles, getAllFiles } = useFileFolder();
	const { favoriteFolder, favoriteFiles, getAllFavorite } = useUserFavoriteFolder();
	const [label, setLabel] = useState(null);
	const [channel, setChannel] = useState(null);
	const [orderBy, setOrderBy] = useState(null);
	const [searchValue, setSearchValue] = useState("");
	const [search] = useDebounce(searchValue, 1000);
	const [selectedFile, setSelectedFile] = useState(null);

	const handleClick = (data, breadcrumbIndex, directoriesLength) => {
		if (breadcrumbIndex !== undefined && directoriesLength !== undefined) {
			if (breadcrumbIndex < directoriesLength - 1) {
				setDirectories([...directories.map((item, index) => {
					if (index <= breadcrumbIndex) {
						return item;
					}
					// else {
					// 	return null;
					// }
				})])
			}
		} else {
			setDirectories([...directories, data]);
		}
		setParentId(data.id);
		setCurrentFolderKey(data.bucketKey)
		setFileManager(API_URL.GET_SINGLE_FILE.replace(":rocketShipId", data.id))
	};


	const handleDrop = (event) => {
		event.preventDefault();
		// setDragging(false);
		const file = event.dataTransfer.files[0];
		setSelectedFile(file);
		// displayImage(file);
	};

	useEffect(() => {
		setFileManager(API_URL.GET_ALL_FILES.replace(":rocketShipId", rocketShipId))
	}, [rocketShipId])

	useEffect(() => {
		getAllFiles(fileManager, {
			label,
			channel,
			orderBy,
			search,
		});
	}, [fileManager, orderBy, search, label, channel]);

	useEffect(() => {
		getAllFavorite(rocketShipId);
	}, [])
	return (
		<>
			<div className="meain_section">
				<div className="sidebar">
					<div onClick={() => { setShowFavourites(false); setFileManager(API_URL.GET_ALL_FILES); setDirectories([]) }} className={`sidebar_feautres ${!showFavourites ? "active" : ""}`}>
						<IoHomeOutline />
						<li>Home</li>
					</div>
					<div onClick={() => setShow(!show)} className={`sidebar_feautres`}>
						<ImFolderUpload />
						<li>Upload</li>
					</div>
					<div onClick={() => handleCreateFolder(rocketShipId, currentFolderKey, parentId, () => getAllFiles(fileManager, {}))} className={`sidebar_feautres`}>
						<ImFolderPlus />
						<li>Create Folder</li>
					</div>
					<div onClick={() => setShowFavourites(true)} className={`sidebar_feautres ${showFavourites ? "active" : ""}`}>
						<ImStarFull />
						<li>Favourite</li>
					</div>
				</div>
				<div className="meainbar"
					onDrop={handleDrop}
					onDragOver={(event) => {
						event.preventDefault();
					}}
					onDragEnter={(event) => {
						event.preventDefault();
					}}
				>
					<HeaderBar
						handleChangeRocketShipId={value => dispatch(setRocketShipId(value))}
						currentFolderKey={currentFolderKey}
						parentId={parentId}
						rocketShipId={rocketShipId}
						getAllFiles={() => getAllFiles(fileManager, {})}
					/>
					<div className="meainbar_inner_two">
						{
							showFavourites ?
								<>
									<div className="browse_files">
										<div className="quick_access">
											<h4>Favorite Files</h4><br></br>
										</div>
										<div className="mt-4 text-start">
											<h5>Folder</h5>
											<div className="box mt-3">
												{
													favoriteFolder.length > 0 ?
														favoriteFolder?.map((item) => {
															return (
																<GridCard
																	key={item.id}
																	id={item.id}
																	handleClick={() => handleClick(item)}
																	name={item.name}
																/>
															)
														}
														)
														:
														<div className="grid_card_box_outer w-100">
															<div className="box_inner">
																<h3>{"Nothing Found"}</h3>
															</div>
														</div>
												}
											</div>
										</div>
										<div className="mt-4 text-start">
											<h5>Files</h5>
											<div className="box">
												{
													favoriteFiles.length > 0 ?
														favoriteFiles?.map((item) =>
															<GridCard
																id={item.id}
																key={item.id}
																handleClick={() => handleClick(item)}
																name={item.name}
															/>
														)
														:
														<div className="grid_card_box_outer w-100">
															<div className="box_inner">
																<h3>{"Nothing Found"}</h3>
															</div>
														</div>
												}
											</div>
										</div>
									</div>
								</>
								:
								<>
									<Breadcrumb className="custom_breadcrumbs">
										<Breadcrumb.Item href={"#"} onClick={() => { setShowFavourites(false); setFileManager(`${API_URL.GET_ALL_FILES.replace(":rocketShipId", rocketShipId)}`); setDirectories([]) }}>Home</Breadcrumb.Item>
										{
											directories.map((item, index, thisArray) => {
												return <Breadcrumb.Item key={item.id} href={"#"} onClick={() => {
													if (index !== thisArray.length - 1) {
														handleClick(item, index, thisArray.length);
													}
												}}> <RightArrow />{item.name}</Breadcrumb.Item>
											})
										}
									</Breadcrumb>
									<div className="view_second_file view_second">
										<div className="mb-4 d-flex align-items-center justify-content-between">
											<h5>Files</h5>
											<div className="view_action_btns">
												<div className="search_box">
													<ImSearch className="search_box_icon" />
													<input
														type="text"
														placeholder="Search"
														className="search_input"
														// value={search}
														onChange={e => {
															setSearchValue(e.target.value);
														}}
													/>
												</div>
												<ReactSelect
													options={channelOpions}
													className="channel_select"
													onChange={(option) => setChannel(option.value)}
													placeholder="Channel"
												/>
												<ReactSelect
													className="label_select"
													options={labelOptions}
													onChange={(option) => setLabel(option.value)}
													placeholder="Label"
												/>
												<ReactSelect
													className="order_select"
													options={orderByOptions}
													placeholder="Order By"
													onChange={(option) => setOrderBy(option.value)}
												/>
												<Button onClick={() => setViewType("grid")} className={viewType === "grid" ? "" : "gray_btn"}><GridViewIcon /></Button>
												<Button onClick={() => setViewType("grid-list")} className={viewType === "grid-list" ? "" : "gray_btn"}><GridAndListViewIcon /></Button>
												<Button onClick={() => setViewType("list")} className={viewType === "list" ? "" : "gray_btn"}><ListViewIcon /></Button>
											</div>
										</div>
										<ViewType
											getAllFiles={() => getAllFiles(fileManager, {})}
											handleFolderClick={handleClick}
											files={allFiles}
											viewType={viewType}
										/>
									</div>
								</>
						}
					</div>
				</div>
			</div >
			{
				showUploading &&
				<UploadingFilesDropzone
					handleClose={() => setShowUploading(false)}
				/>
			}
			{
				(selectedFile || show) &&
				<UploadBtnModal
					selectedFile={selectedFile}
					showAllFiles={() => getAllFiles(fileManager, {})}
					ids={{ currentFolderKey: rocketShipId, parentId: null, rocketShipId: rocketShipId, }}
					show={Boolean(selectedFile) || show}
					handleClose={() => { setShow(false); setSelectedFile(null) }}
				/>
			}
		</>
	);

};

export default Dashlite;
