import React from 'react';
import GridCard from './GridCard';
import GridList from './GridList';
import ListCard from './ListCard';

const ViewType = ({ viewType, getAllFiles, handleFolderClick, files }) => {
    return (
        <>
            {
                viewType === "grid" ?
                    <>
                        <div className="box">
                            {
                                files &&
                                files?.map((item) =>
                                    <GridCard
                                        handleImageClick={() => handleFolderClick(item)}
                                        id={item.id}
                                        getAllFiles={getAllFiles}
                                        key={item.id}
                                        name={item.name}
                                        type={item.fileType}
                                    />
                                )
                            }
                        </div>
                    </>
                    :
                    viewType === "grid-list" ?
                        <div className='view_second_inner'>
                            {
                                files &&
                                files?.map((item) =>
                                    <GridList
                                        handleImageClick={() => handleFolderClick(item)}
                                        id={item.id}
                                        getAllFiles={getAllFiles}
                                        key={item.id}
                                        type={item.fileType}
                                        name={item.name}
                                    />
                                )
                            }
                        </div>
                        :
                        <div className="view_third">
                            {
                                files &&
                                files?.map((item) =>
                                    <ListCard
                                        type={item.fileType}
                                        id={item.id}
                                        getAllFiles={getAllFiles}
                                        handleImageClick={() => handleFolderClick(item)}
                                        key={item.id}
                                        name={item.name}
                                    />
                                )
                            }
                        </div>
            }
        </>
    )
}

export default ViewType
