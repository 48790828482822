
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import folderReducer from "../reducer/folderSlice"
import loadingSlice from "../reducer/loading.slice"
import userSlice from '../reducer/user.slice'
import rocketShipId from '../reducer/rocketShipId.slice'
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const rootReducer = combineReducers({
  folderFile: folderReducer,
  loading: loadingSlice,
  user: userSlice,
  rocketShipId: rocketShipId,
})
const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//   reducer: {
//     folderFile: folderReducer,
//     loading: loadingSlice,
//     user: userSlice,
//   },
// })


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});