import Swal from "sweetalert2";
import { API_URL } from "../../utils/ApiUrl";
import { apiCallPost } from "../../AxiosApi";
import toast from "react-hot-toast";

export const handleCreateFolder = (rocketShipId, currentFolderKey, parentId, getAllFiles,) => {
    const handleSave = async (data, text) => {
        const payload = {
            name: text,
            bucketKey: data.results.key,
            rocketShipId: parentId || String(rocketShipId),
            type: "folder",
            fileType: "folder",
            parentId: parentId,
            label: 'DEFAULT',
            channel: null,
            isDeleted: "false",
            isFavorite: "false",
        };
        try {
            const response = await apiCallPost(API_URL.FILE_SAVE, payload);
            if (!response.error) {
                getAllFiles()
                // onHide()
                toast.success(response.message || "");
            }

        } catch (error) {
            console.error(error);
        }
    };
    Swal.fire({
        title: "Add folder",
        input: "text",
        confirmButtonText: "Create",
        willClose: false,
        showCancelButton: true,
        inputValidator: (value) => {
            if (!value) {
                return "You need to write something!";
            }
        }
    }).then(async data => {
        if (data.isConfirmed) {
            let payload = {
                folderName: data.value,
                currentDirectoryKey: currentFolderKey || String(rocketShipId),
            };
            try {
                const response = await apiCallPost(API_URL.FOLDER, payload);
                if (!response.error) {
                    handleSave(response.data, data.value)
                }
            } catch (error) {
            }
        }
    })
}