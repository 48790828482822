import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  rocketShipId: null,
}

export const rocketShipId = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setRocketShipId: (state, action) => {
      state.rocketShipId = action.payload;
    },

  },
})

// Action creators are generated for each case reducer function
export const { setRocketShipId } = rocketShipId.actions
export default rocketShipId.reducer