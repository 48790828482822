import React from 'react';
import folderIcon from "../../Asssets/Images/folder-alt.svg";
import { getFileTypeIcon } from '../../utils/getFileTypeIcon';
import CardActionDots from './CardActionDots';

const GridCard = ({ handleImageClick, getAllFiles, type, icon, name, id }) => {
    return (
        <>
            <div className="grid_card_box_outer">
                <div className={`box_inner ${type === "folder" ? "folder_box" : ""}`}>
                    <div onClick={type === "folder" ? handleImageClick : () => { }} className="box_img">
                        <img
                            src={
                                type === "file" ? getFileTypeIcon(name) : folderIcon
                            }
                            alt=""
                        />
                    </div>
                    <h3>{name}</h3>
                    {/* <h5>Today . 4.5 MB</h5> */}
                    <CardActionDots showDownloadButton={type !== "folder"} getAllFiles={getAllFiles} name={name} id={id} />
                </div>
            </div >
        </>
    )
}

export default GridCard
