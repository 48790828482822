const base64url = require('base64url');

const Buffer = require('buffer').Buffer;



export const base64urlDecode = async (base64url) => {
    const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');
    const buffer = Buffer.from(base64, 'base64');
    const decodedString = buffer.toString('utf-8');
    const dataArray = decodedString.split('-');
    let data = [];
    if (dataArray) {
        const user_id = dataArray[0];
        const profile_id = dataArray[dataArray.length - 1];
        data['user_id'] = parseInt(user_id);
        data['profile_id'] = parseInt(profile_id);
    }
    return data;
}