import React from 'react';
import folderIcon from "../../Asssets/Images/folder-alt.svg";
import CardActionDots from './CardActionDots';
import { getFileTypeIcon } from '../../utils/getFileTypeIcon';

const GridList = ({ name, id, getAllFiles, handleImageClick, type, handleClick }) => {
    return (
        <>
            <div className="grid_list_card_outer">
                <div className={`view_second_box  ${type === "folder" ? "folder_box" : ""}`}>
                    <div onClick={type === "folder" ? handleImageClick : () => { }} className="view_second_box_top">
                        <div className="view_second_box_image">
                            <img src={type === "file" ? getFileTypeIcon(name) : folderIcon} alt="" />
                        </div>
                        <div className="view_second_box_des">{name || "item.name"}</div>
                    </div>
                    {/* <div className="options_icon">
                           <HiOutlineDotsHorizontal />
                    </div> */}
                    <CardActionDots showDownloadButton={type !== "folder"} name={name} getAllFiles={getAllFiles} id={id} />
                    {/* <div className="view_second_box_about">Today . 4.5 MB . 3 Members</div> */}
                </div>
            </div >
        </>
    )
}

export default GridList
