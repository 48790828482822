import React, { useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import Swal from 'sweetalert2';
import { DownloadIcon, EyeIcon, PenIcon, TrashIcon } from '../../Asssets/icons/icons';
import { apiCallPost } from '../../AxiosApi';
import { API_URL } from '../../utils/ApiUrl';
import { getFileType } from '../../utils/fileTypeHelper';
import ViewDetailsModal from '../modal/ViewDetailsModal/ViewDetailsModal';

const CardActionDots = ({ getAllFiles, showDownloadButton, name, id }) => {
    const ref = useRef(null);
    const [showDetails, setShowDetails] = useState(false);
    const handleRename = () => {
        Swal.fire({
            title: "Rename",
            input: "text",
            inputValue: name,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return "You need to write something!";
                }
                if (getFileType(name) !== "FOLDER") {
                    if (getFileType(value) === "FOLDER") {
                        return "Invalid File Type"
                    }
                }
            },
        })
            .then(async data => {
                if (data.isConfirmed) {
                    const payload = {
                        id,
                        name: data.value,
                    }
                    try {
                        const response = await apiCallPost(API_URL.FILE_RENAME, payload);
                        if (!response.error) {
                            toast.success(response.message || "");
                            getAllFiles();
                        }
                    } catch (error) {
                        console.error(error);
                    }
                }
            })
    }
    const handleDelete = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then(async data => {
            if (data.isConfirmed) {
                const payload = {
                    id,
                    // name: name,
                    key: "uploads/Screenshot from 2024-01-18 15-17-57.png",
                }
                try {
                    const response = await apiCallPost(API_URL.DELETE_FILE, payload);
                    if (!response.error) {
                        toast.success(response.message || "");
                        getAllFiles();
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        })
    };
    const handleDownload = async () => {
        try {
            const response = await apiCallPost(API_URL.DOWNLOAD_FILE, {
                id,
            });
            if (!response.error) {
                window.open(response.data.file, "_blank", "noreferrer");
                ref.current && ref.current.click();
                toast.success(response.message || "");
            }
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <>
            <Dropdown className="dots_icon">
                <Dropdown.Toggle ref={ref}>
                    <HiOutlineDotsHorizontal />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <button onClick={() => setShowDetails(true)}><EyeIcon /> Details</button>
                    <button onClick={handleRename}><PenIcon /> Rename</button>
                    <button onClick={handleDelete}><TrashIcon /> Delete</button>
                    {
                        showDownloadButton &&
                        <button onClick={handleDownload}><DownloadIcon /> Download</button>
                    }
                </Dropdown.Menu>
            </Dropdown>
            <ViewDetailsModal
                show={showDetails}
                handleClose={() => setShowDetails(false)}
            />
        </>
    )
}

export default CardActionDots
