import React, { useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { apiCallPost } from '../AxiosApi';
import Button from '../components/common/Button/Button';
import { setRocketShipId } from '../reducer/rocketShipId.slice';
import { setUser } from '../reducer/user.slice';
import { API_URL } from '../utils/ApiUrl';
import { ROUTES } from '../utils/constant';
import { base64urlDecode } from '../utils/decoder';

const Authenticate = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authenticateUser = useCallback(async () => {
        const payload = {
            token,
        }
        try {
            const response = await apiCallPost(API_URL.AUTHENTICATE, payload, false, false);
            if (!response.error) {
                toast.success(response.message || "");
                const decoded = await base64urlDecode(token)
                dispatch(setRocketShipId(decoded.profile_id));
                dispatch(setUser(response.data));
                navigate(ROUTES.BASE);
            } else if (response.error) {
                navigate(ROUTES.AUTHENTICATION_FAILED);
            }
        } catch (error) {
            console.error(error);
            navigate(ROUTES.AUTHENTICATION_FAILED);
        }
    }, [dispatch, navigate, token])
    authenticateUser();
    // useEffect(() => {
    //     authenticateUser();
    // }, [authenticateUser])
    return (
        <>
            <Button className={"position-fixed top-50 start-50 translate-middle"}>
                <Spinner className='me-3' />
                Authenticating user with rocketship...
            </Button>
        </>
    )
}

export default Authenticate

