import React from 'react'
import "./ViewDetailsModal.scss";
import { Modal } from 'react-bootstrap'
import Button from '../../common/Button/Button'

const ViewDetailsModal = ({ show, handleClose }) => {
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                centered
                className="view_details_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>File Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        <li>
                            <h6>Type</h6>
                            <p>Folder</p>
                        </li>
                        <li>
                            <h6>Size</h6>
                            <p>35.48 MB</p>
                        </li>
                        <li>
                            <h6>Modified</h6>
                            <p>Feb 19, 2020 by Abu Bit Istiyak</p>
                        </li>
                        <li>
                            <h6>Opened</h6>
                            <p>Apr 23, 2020 by Me</p>
                        </li>
                        <li>
                            <h6>Created</h6>
                            <p>Feb 19, 2020</p>
                        </li>
                    </ul>
                    <div className="view_details_footer d-flex mt-4 align-items-center">
                        <Button fluid={true} className="gray_btn me-2 ">Share</Button>
                        <Button fluid={true}>Download</Button>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ViewDetailsModal
